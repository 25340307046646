import { NextResponse } from 'next/server';

import { GameResult } from '@/enums/core';
import { errors } from '@/localization';

import { exception } from '@/utils/core';
import { prisma } from '@/utils/prisma';

export async function findRollback(actionids: string[]) {
  try {
    const rollbackRecords = await prisma.games.findMany({
      where: {
        i_rollback: {
          in: actionids,
        },
        result: GameResult.ROLLBACK,
      },
      select: {
        i_actionid: true,
        i_rollback: true,
        id: true,
        created_at: true,
        i_gameid: true,
      },
    });
    return rollbackRecords;
  } catch (error) {
    await exception(error, { route: '/features/casino/utils/index.ts: findRollback', method: 'GET', req: null });
    return [];
  }
}

export async function updateRollback(id_rollback: string, value: number) {
  try {
    let rollbackRecords = null;
    if (id_rollback && value) {
      const rollbackRecordId = await prisma.games.findFirst({
        where: {
          i_rollback: id_rollback,
          result: GameResult.ROLLBACK,
        },
        select: {
          id: true,
        },
      });

      if (rollbackRecordId && rollbackRecordId.id) {
        rollbackRecords = await prisma.games.update({
          where: {
            id: rollbackRecordId.id,
          },
          data: {
            amount: value,
          },
        });

        return rollbackRecords;
      }
      return null;
    }
  } catch (error) {
    await exception(error, { route: '/features/casino/utils/index.ts: updateRollback', method: 'POST', req: null });
    return NextResponse.json({ message: errors.errorRequestFailed, success: false });
  }
  return null;
}

export async function findDuplicateGames(actionIds: string[]) {
  try {
    const dupes = await prisma.games.findMany({
      where: {
        i_actionid: {
          in: actionIds, // Use the `in` operator to specify an array of values
        },
      },
      select: {
        i_actionid: true,
        id: true,
        created_at: true,
        i_gameid: true,
      },
    });
    return dupes;
  } catch (error) {
    await exception(error, { route: '/features/casino/utils/index.ts: findDuplicateGames', method: 'GET', req: null });
    return [];
  }
}

export async function createGames(actions: any) {
  try {
    await prisma.games.createMany({
      data: actions.map((action: any) => {
        return {
          user_id: action.user_id,
          provider: action.provider,
          result: action.result,
          amount: action.amount,
          currency: action.currency,
          i_gameid: action.i_gameid,
          i_gamedesc: action.i_gamedesc,
          i_actionid: action.i_actionid,
          jackpot_win: action.jackpot_win,
          rolled_back: action.rolled_back,
          i_rollback: action.i_rollback,
        };
      }),
    });

    // Extract the ids of the inserted records
    const insertedIds = actions.map((action: any) => {
      return {
        user_id: action.user_id,
        i_actionid: action.i_actionid,
        i_gameid: action.i_gameid,
      };
    });

    // Query the inserted records based on the extracted ids
    const insertedJuegos = await prisma.games.findMany({
      where: {
        OR: insertedIds.map(({ user_id, i_actionid, i_gameid }: any) => {
          return {
            AND: [
              { user_id },
              { i_actionid },
              { i_gameid },
            ],
          };
        }),
      },
    });

    return insertedJuegos;
  } catch (error) {
    await exception(error, { route: '/features/casino/utils/index.ts: createGames', method: 'POST', req: null });
    return [];
  }
}

export async function updateFreespin(issue_id: number, status: number, total_amount: number): Promise<any> {
  try {
    // Start a transaction
    const result = await prisma.$transaction(async (p) => {
      // First operation: Update freeSpins
      const updatedFreespin = await p.freeSpins.update({
        where: {
          id: issue_id,
        },
        data: {
          status,
          amount_won: total_amount,
          updated_at: new Date(),
        },
      });

      // Second operation: Get user balance, contingent on success of first operation
      const userBalance = await p.users.findFirst({
        where: {
          id: updatedFreespin.user_id,
        },
        select: {
          deposit_balance: true,
          available_balance: true,
        },
      });

      return userBalance;
    });

    return result;
  } catch (error) {
    await exception(error, { route: '/features/casino/utils/index.ts: updateFreespin', method: 'POST', req: null });
    return [];
  }
}

export async function findRollbackAmount(id_action: string) {
  try {
    const rollbackAmount = await prisma.games.findFirst({
      where: {
        i_actionid: id_action,
        result: {
          not: GameResult.ROLLBACK,
        },
      },
      select: {
        amount: true,
      },
    });
    if (rollbackAmount) {
      return rollbackAmount.amount;
    }
    return 0;
  } catch (error) {
    await exception(error, { route: '/features/casino/utils/index.ts: findRollbackAmount', method: 'GET', req: null });
    return 0;
  }
}

export async function markGamesAsRolledBack(originalActionIds: any[]) {
  try {
    originalActionIds.forEach(async ({ originalActionId, rollbackActionId }) => {
      await prisma.games.updateMany({
        where: {
          i_actionid: originalActionId,
        },
        data: {
          rolled_back: true,
          i_rollback: rollbackActionId,
        },
      });
    });
    return true;
  } catch (error) {
    await exception(error, { route: '/features/casino/utils/index.ts: markGamesAsRolledBack', method: 'POST', req: null });
    return false;
  }
}

export async function createBonus(actions: any) {
  try {
    return await prisma.betBonuses.create({
      data: actions,
    });
  } catch (error) {
    await exception(error, { route: '/features/casino/utils/index.ts: createBonus', method: 'POST', req: null });
    throw error;
  }
}

export function CategoriesGames(data: any, IconCategory: any) {
  if (!data.gameData || data.gameData.length === 0) {
    return [];
  }
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const gamesFiltered = data.gameData.filter((game: any) => { return Object.keys(game.sections).length > 0; });
  const setedCategory = data.sectionsGames.map((category: any) => {
    return {
      headline: capitalizeFirstLetter(category),
      items: gamesFiltered.filter((game: any) => { return game.sections[category]; }).sort((a: any, b: any) => { return a.sections[category] - b.sections[category]; }),
      url: '/casino',
      icon: IconCategory[category as keyof typeof IconCategory],
    };
  });

  return setedCategory;
}

export const burnedCategories = (data: any, IconCategory: any) => {
  const burned = [
    {
      headline: 'Rasca y gana',
      items: data.filter((game: any) => { return game.category === 'scratch'; }),
      url: '/casino',
      icon: IconCategory.scratch,
    },
    {
      headline: 'Tragamonedas',
      items: data.filter((game: any) => { return game.category === 'slots'; }),
      url: '/casino',
      icon: IconCategory.slots,
    },
    {
      headline: 'Ruleta',
      items: data.filter((game: any) => { return game.category === 'roulette'; }),
      url: '/casino',
      icon: IconCategory.roulette,
    },
    {
      headline: 'Cartas',
      items: data.filter((game: any) => { return game.category === 'card'; }),
      url: '/casino',
      icon: IconCategory.card,
    },
    {
      headline: 'Loteria',
      items: data.filter((game: any) => { return game.category === 'lottery'; }),
      url: '/casino',
      icon: IconCategory.lottery,
    },
    {
      headline: 'Dados',
      items: data.filter((game: any) => { return game.category === 'craps'; }),
      url: '/casino',
      icon: IconCategory.dice,
    },
    {
      headline: 'Arcade',
      items: data.filter((game: any) => { return game.category === 'casual'; }),
      url: '/casino',
      icon: IconCategory.casual,
    },
  ];

  return burned;
};
