import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/home/HomeMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navbar/NavigationBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navbar/SideBar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/icons/honduras2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/logo/hondubetlogo.png");
