'use client';

import React from 'react';

import { Session } from 'next-auth';

import { userUIStore } from '@/stores/userUIStore';

import HomeTab from './HomeTab';

const HomeMain = ({ session }: { session: Session | null }) => {
  const { isSidebarOpen } = userUIStore();
  // const searchParams = useSearchParams();
  // const registration = searchParams.get('reg');
  // const deposit = searchParams.get('deposit');

  return (
    <div className={`${!isSidebarOpen ? 'pl-72' : 'pl-20'} w-full pt-20 max-lg:pl-0 max-lg:pt-[4.2rem]`}>
      <HomeTab session={session} />
    </div>
  );
};

export default HomeMain;
